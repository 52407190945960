//
// Base styles
//

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: var(--#{$variable-prefix}accordion-button-color, $accordion-button-color);
  text-align: left; // Reset button style
  background-color: var(--#{$variable-prefix}accordion-button-bg, $accordion-button-bg);
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: var(--#{$variable-prefix}accordion-button-active-color, $accordion-button-active-color);
    background-color: var(
      --#{$variable-prefix}accordion-button-active-bg,
      $accordion-button-active-bg
    );
    box-shadow: inset 0 calc(var(--cui-border-width) * -1) 0
      var(--#{$variable-prefix}accordion-border-color, #{$accordion-border-color});

    &::after {
      background-image: var(
        --#{$variable-prefix}accordion-button-active-icon,
        escape-svg($accordion-button-active-icon)
      );
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    @include ltr-rtl('margin-left', auto);
    content: '';
    background-image: var(
      --#{$variable-prefix}accordion-button-icon,
      escape-svg($accordion-button-icon)
    );
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: var(
      --#{$variable-prefix}accordion-button-focus-border-color,
      $accordion-button-focus-border-color
    );
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: var(--#{$variable-prefix}accordion-bg, $accordion-bg);
  border: $accordion-border-width solid
    var(--#{$variable-prefix}accordion-border-color, $accordion-border-color);

  &:first-of-type {
    @include border-top-radius($accordion-border-radius);

    .accordion-button {
      @include border-top-radius($accordion-inner-border-radius);
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    @include border-bottom-radius($accordion-border-radius);

    .accordion-button {
      &.collapsed {
        @include border-bottom-radius($accordion-inner-border-radius);
      }
    }

    .accordion-collapse {
      @include border-bottom-radius($accordion-border-radius);
    }
  }
}

.accordion-body {
  padding: $accordion-body-padding-y $accordion-body-padding-x;
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .accordion-button {
      @include border-radius(0);
    }
  }
}

.return-accordion {
  .accordion-button {
    background-color: var(--cui-card-cap-bg);
    border-radius: 0;
    padding: 0.5rem 1rem;
  }
  .accordion-item {
    border: 0;
    border-bottom: var(--cui-card-border-width) solid var(--cui-card-border-color);
  }
}
